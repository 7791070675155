<template>
  <div class="page page-company-form">
    <div class="card card-custom gutter-b example example-compact">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">
            <div v-if="originalData.name">
              <small>Telefon Rehberi</small><br />
              <span>{{ originalData.name }} adlı kayıt</span>
            </div>
            <span v-else>Yeni Telefon Ekle</span>
          </h3>
        </div>
        <div class="d-block w-100 mb-2">
          <rs-return-to />
        </div>
      </div>
      <div class="card-body">
        <v-form ref="form" @submit.prevent="handleFormSubmit">
          <v-row dense>
            <v-col cols="4">
              <rs-text-field
                label="Ad"
                :rules="[rules.required, rules.maxLength(formData.name, 100)]"
                v-model="formData.name"
              />
            </v-col>
            <v-col cols="6">
              <rs-tel-field
                :label="$t('labels.phone')"
                v-model="formData.phone"
                :rules="[
                  formData.phone != ''
                    ? rules.minLength(formData.phone, 7)
                    : null,
                  rules.numbersOnlyWithParam,
                ]"
              />
            </v-col>
            <v-col cols="2">
              <v-switch
                v-model="formData.is_active"
                :label="formData.is_active ? $t('active') : $t('passive')"
                hide-details="auto"
              />
            </v-col>
          </v-row>

          <rs-form-buttons
            :is-loading="isLoading"
            @cancel="handleCancelClick"
            @submit="handleFormSubmit"
          />
        </v-form>
      </div>
    </div>
  </div>
</template>

<script>
import hasForm from "@/view/mixins/hasForm";
import { mapActions, mapGetters } from "vuex";

export default {
  mixins: [hasForm],
  computed: {
    ...mapGetters(["clusterId"]),
  },
  props: {
    id: {
      type: Number,
      required: false,
      default: null,
    },
  },
  watch: {
    id() {
      this.load();
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.id) {
        this.load();
      }
    });
  },
  data() {
    return {
      formData: {
        name: null,
        phone: null,
        is_active: true,
      },
    };
  },
  methods: {
    ...mapActions(["updateClusterId"]),
    /**
     * Retrieves item data
     * @return {Promise} Resolves if data is retrieved
     */
    load() {
      this.isLoading = true;

      return this.$api
        .query(`cluster-phones/${this.id}`)
        .then((response) => {
          this.loadData(response);
          if (this.clusterId) {
            this.updateClusterId(this.clusterId);
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleFormSubmit(event) {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      this.isLoading = true;
      const formData = Object.assign({}, this.formData);
      if (formData.phone) {
        formData.phone = this.formData.phone.replace(/[^0-9+]/g, "");
      }

      formData.cluster_id = this.clusterId;
      if (this.id) {
        this.$api
          .put(`cluster-phones/${this.id}`, formData)
          .then((response) => {
            this.$toast.success("Kaydedildi");
            if (event && event.closeOnSave) {
              this.$router.back();
              return;
            }

            this.loadData(response);
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        this.$api
          .post(`cluster-phones`, formData)
          .then((response) => {
            this.$toast.success("Kaydedildi");
            if (event && event.closeOnSave) {
              this.$router.back();
              return;
            }

            this.$router.replace({
              name: "other-definitions.cluster-phones.edit",
              params: { id: response.data.data.id },
            });
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    handleCancelClick() {
      this.$router.back();
    },
  },
};
</script>

<style lang="scss">
.v-input--checkbox {
  label {
    margin-bottom: 0;
  }
}
</style>
